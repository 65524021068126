import React from "react"

import Container from "components/container"
import { DriveLayout } from "components/layout-custom"
import PersonCalendar from "components/person-calendar"

import { Audience } from "types/audience"

import { driverRotaPublishedFromDate } from "utils/date-utils"

const Page = () => {
  return (
    <DriveLayout title="Calendar" padded={true}>
      <Container>
        <PersonCalendar
          audience={Audience.driver}
          minDate={new Date(driverRotaPublishedFromDate())}
        />
      </Container>
    </DriveLayout>
  )
}

export default Page
